// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import HdVideoFormats from "../../blocks/HdVideoFormats/src/HdVideoFormats";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import UserStatus from "../../blocks/UserStatus/src/UserStatus";
import Customform from "../../blocks/customform/src/Customform";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Download from "../../blocks/Download/src/Download";
import Calendar from "../../blocks/Calendar/src/Calendar";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import VideoAds from "../../blocks/VideoAds/src/VideoAds";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";



const routeMap = {
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
LeadManagement:{
 component:LeadManagement,
path:"/LeadManagement"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
HdVideoFormats:{
 component:HdVideoFormats,
path:"/HdVideoFormats"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
Customform:{
 component:Customform,
path:"/Customform"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Download:{
 component:Download,
path:"/Download"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
VideoAds:{
 component:VideoAds,
path:"/VideoAds"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;